import React from "react";
import styled from "styled-components";
import { fontStacks, fontSizes, colors } from "../global-style";

export const Button = styled.button`
  font-family: ${fontStacks.prequelDemo};
  font-size: ${fontSizes[2]};
  text-transform: uppercase;
  background-color: ${colors.yellow};
  border: none;
  padding-top: 0.8rem;
  padding-bottom: 0.75rem;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  border-radius: 1.5rem;
  cursor: pointer;
  transition: all 0.1s ease;
  outline: none;
  display: flex;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.09);
  }
`;
