import React from "react";
import { Link } from "gatsby";
import { fontSizes } from "../global-style";

export const Logo = () => {
  return (
    <Link to="/">
      <div
        style={{
          position: "relative",
          display: "inline-block",
        }}
      >
        <div
          style={{
            position: "absolute",
            height: "100%",
            width: "100%",
            top: "0.75rem",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
          }}
        >
          <h2 style={{ fontSize: fontSizes[2] }}>
            Werken
            <br />
            bij een
            <br />
            bakker
            <br />
            <p style={{ transform: "rotate(-3deg) translateY(-0.25rem)" }}>
              ———
            </p>
          </h2>
        </div>

        <svg width={164.731} height={166.329} viewBox="0 0 164.731 166.329">
          <path
            d="M137.657 101.058c-5.76 15.772-16.033 31.397-31.438 40.35s-35.921 11.226-50.167 4.783c-14.26-6.464-22.196-21.643-29.437-37.171s-13.767-31.365-9.583-46.508 19.042-29.53 35.3-34.43c16.23-4.886 33.857-.26 49.686 5.552 15.816 5.792 29.887 12.773 36.268 24.22 6.392 11.474 5.131 27.432-.63 43.204z"
            fill="#ffb705"
          />
        </svg>
      </div>
    </Link>
  );
};
